.container {
  display: flex;
  align-items: center;
  min-width: 5px;
  min-height: 32px;
}

.autocomplete {
  min-width: 180px;
}

.text-field {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.currency-field {
  max-width: 60px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
  min-width: 60px;
}

input[type=text] {
  min-width: 180px;
}