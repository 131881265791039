.CalendarDay__selected_span {
  background: #1976d2;
  color: white;
  border: 1px solid white;
}

.CalendarDay__selected_span:hover {
  background: black;
  color: white;
  border: 1px solid black;
}

.CalendarDay__selected {
  background: #01579b;
  color: white;
}

.CalendarDay__selected:hover {
  background: black;
  color: white;
  border: 1px solid black;
}

.PresetDateRangePicker_button {
  border: 2px solid #1976d2;
  color: #1976d2;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #1976d2;
}

.DateInput_input__focused {
  border-bottom: 2px solid #1976d2;
}
