body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cancelado {
  font-style: italic;
}

.cancelado-detalhes {
  font-weight: 900;
  font-style: italic;
  background: red;
  color: white;
}